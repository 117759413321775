import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import blog10 from "../../images/daycare-worker.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";

export default function RecognizingCenters() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundImage: "url(" + blog10 + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Recognizing Our Early Education and Care Centers</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Recognizing Our Early Education and Care Centers 
					</h2>
				
					<p>May 2024</p>

					<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
					</p>

					&nbsp;

					<p>As we developed the CMV TransmIT Study, building a network of Early Education and Care (EEC) centers formed the core of our study design.   While visiting these centers, research nurses provide CMV education, introduce the study, and offer enrollment to families.  We highlight our common goal to improve the health of children, families, and communities. </p>
					<p>Our flagship EEC center was the YWCA of Central Massachusetts in Worcester, Massachusetts. We met Darlene Belliveau, Director of Children’s Services, who welcomed us and connected our team to other centers in the area. </p>
					<p>As we recognize Childcare Provider Day this May 10, we thank all who attend to the well-being and education of our children and provide a safe and healthy environment for them to grow, learn, and play.  Without our partner centers and their dedicated leaders like Darlene, the CMV TransmIT Study would not be possible.  </p>
					<p>This month, I am sharing an interview with Darlene so you can hear in her own words about why the YWCA chose to join our research network and how her engagement with our study team is helping inform our work. </p>
					<p><ol>
						<li>
						<p><b>What is your role at the YWCA of Central Massachusetts?</b></p>
						<p>I work as the Director of Children’s Services, and oversee two Early Childhood Programs, two After-School Programs, and a Summer Camp. My role is to ensure that we are providing high quality programming, while providing strategic leadership to align with polices while also overseeing the planning, development, and delivery of care to spark each child’s natural curiosity and promote age-appropriate learning. My goal is to implement a strategic vision that encompasses the whole child and family.</p> 
						</li>
						<li>
						<p><b>Why did the YWCA decide to join the CMV TransmIT Study research network? Do you think other early education and care centers should participate in the study’s research network?</b></p>
						<p>We decided to join the CMV TransmIT Study to help build a network of centers and families so we can advance CMV research toward reducing the number of babies born with the virus and support promoting a healthy community. I believe other childcare centers should join the study to learn about the risk for exposure and together we could learn best practices to support the research and review our cleaning and sanitizing procedures.</p> 
						</li>
						<li>
							<p><b>How do you think the YWCA of Central Massachusetts membership in the research network for this study benefit the center families?</b></p>
							<p>As a provider, our objective for families was to raise awareness of the CMV infection, include the parents in the educational component, and together, review our conventional hygienic measures at school and at home so we can help reduce transmission of CMV.</p>
						</li>
						<li>
							<p><b>How has the CMV TransmIT Study team at your center raised your awareness about CMV?</b></p>
							<p>Every time I attended a meeting with the study team, I learned something new. As a director, it has empowered me to review our health/safety policies to ensure we are doing everything we can to prevent infection to children and pregnant moms.</p>
						</li>
						<li>
							<p><b>As a member of the CMV TransmIT Study Community Advisory Board, what do you feel is the benefit of being part of the Board?</b></p>
							<p>It is exciting to know you are informing research and giving insights and suggestions that UMass Chan may not have considered recruiting families to participate in the study. I am gaining new skills and knowledge and contributing in a meaningful way. This research has me thinking differently, personally, and professionally, and contributes to a group environment.  </p>
						</li>
						<li>
							<p><b>Reflecting on your experience joining the research network, attending education sessions, participating on the Board, and engaging with study staff, is there anything else you would like to add?</b></p>
							<p>I am happy to be a part of this research and advisory board, and look forward to continuing this partnership we have solidified with UMass Chan. </p>
						</li>
					</ol></p>

					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}