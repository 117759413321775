import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import blog07 from "../../images/holding-hands.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";

export default function RememberingBabyLogan() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundImage: "url(" + blog07 + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Remembering Baby Logan</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Remembering Baby Logan:
						<pre>An Interview with a CMV Mom</pre>
					</h2>

					<p>October 2023</p>

					<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
					</p>

					&nbsp;

					<p>I met Vanessa and Peter Colleran in 2017 when their son Logan was born three months before his due date. He had been infected with cytomegalovirus (CMV), the most common infection before birth and a major cause of lifetime neurodevelopmental disabilities. After four months in the Neonatal Intensive Care Unit, baby Logan passed away from severe CMV infection and resistance to the medication treating the virus. Since then, the Collerans have been passionate about educating the public—especially people who are or may become pregnant—and medical professionals about cCMV and how to prevent this potentially life-threatening disease.
					</p>
					<p>Every October, we pause to remember baby Logan and all the babies who died of this virus during Pregnancy and Infant Loss Awareness Month.
					</p>
					<p>This month, I’m sharing an interview with Vanessa so you can hear in a mother’s own words about the impact of cCMV and how she is working to raise awareness in memory of Logan.
					</p>
					&nbsp;
					<p><strong>When did you learn baby Logan was diagnosed with cCMV? Do you know how he contracted the virus?</strong></p>
					<p><i>Logan was diagnosed with cCMV when he was just a few days old. I don’t recall being sick during my pregnancy or know how I got the virus, but I suspect it was through the bodily fluids of my firstborn son.</i></p>
					<p><strong>What would you tell pregnant people and families about cCMV?</strong></p>
					<p><i>Knowledge is power! If you are pregnant and are in frequent contact with young children, you are at a higher risk of contracting CMV and should know about this virus. Since exposure is through saliva and urine, the good news is there are simple changes you can make to lower your risk. Here’s just some examples:
							<p>While pregnant:
							<ol>
								<li>Kiss your child on the forehead instead of lips</li>
								<li>Don’t share utensils or cups with them</li>
								<li>Don’t put your child’s pacifier in your mouth</li>
								<li>Wash your hands after every diaper change</li>
								<li>Wash your hands after wiping runny noses</li>
							</ol>
							</p>
						</i>
					</p>
					<p><strong>You have been a strong advocate for cCMV awareness and for universal newborn CMV screening over the past several years. Why do you think this is important?</strong></p>
					<p><i>Had I known about cCMV and its prevention, Logan might have been healthy…he might still be here. I don’t blame myself or anyone, but if my knowledge can help prevent others from the pain my family and I had to endure, then it’s worth it.
					Universal screening is important to me because most babies born with cCMV are asymptomatic at first, but then later develop effects, such as hearing loss. Without universal screening, most parents will never know the true cause of their child’s challenges and have missed out on the opportunity for early treatment and intervention.</i></p>
					<p><strong>Can you share a bit about baby Logan, such as his personality or what he liked or didn’t like?</strong></p>
					<p><i>Logan loved snuggling with his mom, dad, and nurses, and listening to lullabies and his dad’s singing. He was also a tough and feisty little guy, having to put up with lots poking and prodding each day. I wonder what he would have been like today—he would have been six years old.</i></p>
					<p><strong>How can people learn more about cCMV? </strong></p>
					<p><i>Visit the websites of the Massachusetts CMV Coalition <a href="https://www.cmvmass.org">(cmvmass.org)</a> and the National CMV Foundation <a href="https://www.nationalcmv.org">nationalcmv.org</a>.</i></p>
					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}