import React, { useEffect } from "react";
import { useParams, Link, generatePath } from "react-router-dom";
import { routes } from "../routes";
import AOS from "aos";
import hero_bk from "../images/kids-sharing.jpg";
import hero_img from "../images/kids-sharing.jpg";
import cmvWhite from "../images/cmv-transmit-white-text.svg";
import kids01 from "../images/kids-with-parents.jpg";
import kids02 from "../images/kids01.jpg";
import wave from "../images/wave-f5f5f5.svg";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import MyDataHelps from "@careevolution/mydatahelps-js";
import Nav from "../partials/nav";
import CtaModule from "../partials/cta-module";
import Footer from "../partials/footer";
import getBrandConfiguration from "../helpers/getBrandConfiguration"
import {
	useSearchParams
} from "react-router-dom";

export default function Home() {

  let { locale } = useParams();
  const { t, i18n } = useTranslation();

  const [search] = useSearchParams();

  const VaxUrl = "https://www.washtenaw.org/3269/COVID-19-Vaccination"
 
  const tagManagerArgs = {
    dataLayer: {
      event: "page_view",
      page_location: document.location.href,
      page_title: t("home-meta-title"),
    },
    };

  var brandConfiguration = getBrandConfiguration();
  function join() {
    console.log("MyDataHelps.startEmbeddedSurvey")
    MyDataHelps.startEmbeddedSurvey(brandConfiguration.projectCode, "Consent").then(function (result) {
      if (result.reason === "Completed") {
          //redirect to confirmation page with the link identifier in the url
          const accessKeyObj = result.taskResult.StepResults.find(obj => obj.Identifier === "AccessKey");
          const accessKey = accessKeyObj && accessKeyObj.QuestionResults[0].Result;

          window.location = window.location.origin + "/confirmation/" + (locale ?? "en") + "?participant=" + accessKey;
      }
    });
  }

  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    document.title = t("home-meta-title");
    AOS.init({
      duration : 2000
    });
  }, []);
  return (
    <>
    <Nav />
    <main className="home-page">
    <section data-aos="zoom-y-out" className="hero" >
      <div className="container fifty-50 col-2-md">
        <div className="section-text">
          <h1>{t("hero-h")}</h1>
          <h3>Do you have a child up to and including 36 months of age in an early education and care center in the Worcester or Cambridge areas of Massachusetts?
          </h3>
          <button className="button" onClick={() => join()}>
            <span className="text-wrap">Determine eligibility</span>
            <span className="jump-arrow">
              <i className="fas fa-arrow-right">
                <span className="sr-only">go</span>
              </i>
            </span>
          </button >
        </div>
        <div className="section-image">
          <img className="background" src={hero_img} alt="" />
          <div className="logo" style={{"PaddingRight": "34px"}}>
            <img src={cmvWhite} alt="" />
          </div>
        </div>
      </div>
      <img className="home-hero-wave" src={wave} alt="" />
    </section>


      <section data-aos="" className="about-cmv">
        &nbsp;
        <div className="container col-2-md">
        <div className="section-image">
          &nbsp;
            <img src={kids01} alt="" />
          </div>
          <div className="section-text">
              <h2>What is cytomegalovirus?
              </h2>
              <p>Cytomegalovirus (CMV) is the most common prenatal infection. CMV can cause a range of major health problems in newborns. Pregnant people who are exposed to large groups of young children like childcare or preschools are at high risk for CMV infection and need to take precautions. 
              </p>
              <Link 
              to={generatePath(routes.whatIsCmv, { locale: i18n.resolvedLanguage })}
              className="secondary button">
              <span className="text-wrap">Learn more</span>
              <span className="jump-arrow">
                <i className="fas fa-arrow-right">
                  <span className="sr-only">go</span>
                </i>
              </span>
              </Link>
          </div>
        </div>
      </section>

      <section className="highlighted stats first-content-section">
        <div className="container">
        &nbsp;
          <h2 className="center no-subtext">CMV by the numbers</h2>
          <div className="stats">
           <div className="stat-wrap">
                <div className="stat-number"><span>more than</span><strong>91%</strong></div>
                <div className="stat-text">of women do not know about CMV or how it can affect babies before birth.</div>
           </div>
           <div className="stat-wrap">
                <div className="stat-number"><span>about</span><strong>1/200</strong></div>
                <div className="stat-text">babies is born with cCMV infection. </div>
           </div>
           <div className="stat-wrap">
                <div className="stat-number"><span>about</span><strong>30,000</strong></div>
                <div className="stat-text">babies are born with cCMV every year in the U.S.</div>
           </div>
           <div className="stat-wrap">
                <div className="stat-number"><span>about</span><strong>10-15%</strong></div>
                <div className="stat-text">of babies with cCMV have health problems at birth.</div>
           </div>
           <div className="stat-wrap">
                <div className="stat-number"><span>about</span><strong>15%</strong></div>
                <div className="stat-text">of babies with cCMV appear normal at birth and develop health problems later.</div>
           </div>
           <div className="stat-wrap">
                <div className="stat-number"><span>nearly</span><strong>1/5</strong></div>
                <div className="stat-text">babies with cCMV have permanent disabilities such as hearing loss, cerebral palsy, or delays in development.</div>
           </div>

           <div className="stat-wrap">
                <div className="stat-number"><span>approx.</span><strong>ONE</strong></div>
                <div className="stat-text">child is permanently disabled by cCMV every hour.</div>
           </div>
           <div className="stat-wrap">
                <div className="stat-number"><span>around</span><strong>400</strong></div>
                <div className="stat-text">babies die of cCMV every year.</div>
           </div>

          </div>
          <div className="stats-refs">
            <div>References:</div>
            <ul className="stats-refs-items">
              <li><a href="https://www.cdc.gov/cmv/" target="_blank">CDC CMV Resources</a></li>
              <li><a href="https://www.nationalcmv.org/" target="_blank">National CMV Foundation</a></li>
              <li><a href="https://nowiknowcmv.com/" target="_blank">Now I Know CMV (Moderna)</a></li>
              <li><a href="https://cmvmass.org/" target="_blank">Massachusetts CCMV Coalition</a></li>
            </ul>
          </div>
        </div>
      </section>





      <section data-aos="zoom-y-out" className="">
        <div className="container fifty-50 col-2-md">
           <div className="section-text">
            <h2>What is this study about?</h2>
            <p>Researchers want to study how cytomegalovirus (CMV) spreads among young children in early education and care centers. Results of the study may help decrease CMV spread from these children to pregnant people caring for them. </p>
            <Link 
                to={generatePath(routes.aboutTheStudy, { locale: i18n.resolvedLanguage })}
            className="secondary button">
              <span className="text-wrap">Learn more</span>
              <span className="jump-arrow">
                <i className="fas fa-arrow-right">
                  <span className="sr-only">go</span>
                </i>
              </span>
            </Link>
          </div>
          <div className="section-image">
            <img className="hide-under-md" src={kids02} alt=""/>
          </div>
        </div>
      </section>


      <section className="highlighted">
        <div className="container">
        <div className="center">
        <h2>How do I reduce my risk of contracting CMV?</h2>
        <p>If you are pregnant, you can help protect your unborn baby from cCMV by avoiding contact with saliva and urine from babies and young children.          
        </p>
        </div>
        <div className="risks">
        <div className="card-wrap">
        <div className="card">
            <div className="icon-wrap">
              <i className="fas fa-face-kiss-wink-heart">
                <span className="sr-only">kiss</span>
              </i>
            </div>
            <div className="tile-text">Avoid kissing a child on the lips or cheek. Instead, kiss the top of their head, blow a kiss, or give them a hug.
            </div>
          </div>
        </div>
        <div className="card-wrap">
        <div className="card">
            <div className="icon-wrap">
              <i className="fas fa-utensils">
                <span className="sr-only">kiss</span>
              </i>
            </div>
            <div className="tile-text">Do not share food, utensils, drinks, straws, pacifiers, or toothbrushes with a child. 
            </div>
          </div>
        </div>
          <div className="card-wrap">
          <div className="card">
            <div className="icon-wrap">
              <i className="fas fa-hands-bubbles">
                <span className="sr-only">sparkling hands</span>
              </i>
            </div>
            <div className="tile-text">Wash your hands after changing diapers, wiping a child’s face, feeding a child, or handling toys.
            </div>
          </div>
          </div>
          <div className="card-wrap">
            <div className="card">
              <div className="icon-wrap">
                <i className="fas fa-spray-can-sparkles">
                  <span className="sr-only">disinfectant</span>
                </i>
              </div>
              <div className="tile-text">Disinfect toys, countertops, and any other surfaces that might come in contact with a child’s urine or saliva.
              </div>
            </div>
          </div>
        </div>    
        </div>
      </section>


     <CtaModule locale={locale} />
    </main>
    <Footer />
    </>

  );
}
