import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import blog09 from "../../images/scientist.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";

export default function CheckUp() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundImage: "url(" + blog09 + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Check-up!</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Check-up!
					</h2>
				
					<p>December 2023</p>

					<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease specialist at UMass Chan Medical School</i>
					</p>

					&nbsp;

					<p>
						Back in April 2023, the CMV Transmission and Immune Tracking (CMV TransmIT) Study obtained approval from the UMass Chan Institutional Review Board to begin enrollment. As you may already know, we are studying how CMV spreads among young children in early education and care (EEC) centers and how the body fights CMV in saliva and urine. Our results may help decrease CMV spread from children to their pregnant caretakers at home or work, which could also lower the risk of congenital CMV infection.
					</p>
					<p>
						Along the way, we have discovered the remarkable and unique communities of each childcare center. Directors seem to know their families intimately and to weave their best interests into daily activities. At centers children appear comfortable and engaged with their environment. Families and centers may face a variety of distinct challenges, but their commitment to the health of their children is astonishing to witness.
					</p>
					<p>
						Our expanding research network of centers is only possible with the growth of our incredible study team. We are developing our own community of dedicated professionals who share the energy and commitment we experience at centers. As we look back on the past year, here are some of our accomplishments!
					</p>
					<h2>CMV TransmIT Study By-The-Numbers</h2>
					<p>
						<b>1</b> study sponsor, principal investigator, co-principal investigator, and research program manager<br></br> 
						<b>2</b> administrative support professionals, data management team members, and leadership members<br></br>
						<b>3</b> medical students and consultants<br></br>
						<b>4</b> research nurses and Community Advisory Board meetings held<br></br>
						<b>8</b> participating early education centers<br></br>
						<b>9</b> staff and parent education sessions<br></br>
						<b>100</b> people attending education sessions
					</p>
					&nbsp;
					<p>
						To learn more about the study, visit <a href="https://cmvtransmitstudy.org/" target="_blank">CMV TransmIT Study website</a>.
					</p>
					<p>
						For more information about CMV, visit the <a href="https://www.nationalcmv.org/default.aspx" target="_blank">National CMV Foundation</a> or the <a href="https://www.cdc.gov/cmv/index.html" target="_blank">Centers for Disease Control and Prevention CMV page</a>.
					</p>

					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}