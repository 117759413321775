import { matchRoutes, useLocation } from "react-router-dom";

export const routes = {
  root: "/:locale",
  home: "/home/:locale",
  updates: "/updates/:locale",
  whatIsCmv: "/what-is-cmv/:locale",
  aboutTheStudy: "/about-the-study/:locale",
  infoForCenters: "/information-for-centers/:locale",
  newbornScreening: "/newbornScreening/:locale",
  takingAChance: "/taking-a-chance/:locale",
  cmvInNewborns: "/cmv-in-newborns/:locale",
  cmvAwareness: "/cmv-awareness/:locale",
  timeForACheckup: "/time-for-a-checkup/:locale",
  recognizingCenters: "/recognizing-centers/:locale",
  awarenessAndIntervention: "/awareness-and-intervention/:locale",
  rememberingBabyLogan: "/remembering-baby-logan/:locale",
  advocacyAndAwareness: "/advocacy-and-awareness/:locale",
  preventingCmv: "/preventing-cmv/:locale",
  collaborationIsKey: "/collaboration-is-key/:locale",
  checkUp: "/check-up/:locale",
  confirmation: "/confirmation/:locale",
  meetTeam: "/meet-team/:locale",
  contactUs: "/contact-us/:locale"

  
};
export const routesBase = {
  root: "/",
  home: "/home",
  updates: "/updates",
  whatIsCmv: "/what-is-cmv",
  aboutTheStudy: "/about-the-study",
  infoForCenters: "/information-for-centers",
  newbornScreening: "/newborn-screening",
  takingAChance: "/taking-a-chance",
  cmvInNewborns: "/cmv-in-newborns",
  cmvAwareness: "/cmv-awareness",
  timeForACheckup: "/time-for-a-checkup",
  recognizingCenters: "/recognizing-centers",
  awarenessAndIntervention: "/awareness-and-intervention",
  rememberingBabyLogan: "/remembering-baby-logan",
  advocacyAndAwareness: "/advocacy-and-awareness",
  preventingCmv: "/preventing-cmv",
  collaborationIsKey: "/collaboration-is-key",
  checkUp: "/check-up",
  confirmation: "/confirmation",
  meetTeam: "/meet-team",
  contactUs: "/contact-us"

};  

const routerRoutes = Object.values(routes).map((path) => ({ path }));

export function usePathPattern() {
  const location = useLocation();
  const match = matchRoutes(routerRoutes, location);
  if (match) {
    return match[0]?.route?.path;
  }
  return routes.root;
}
