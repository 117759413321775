import React, { useEffect } from "react";
import AOS from "aos";

import { useTranslation } from "react-i18next";

import TagManager from "react-gtm-module";

import Footer from "../partials/footer";
import Nav from "../partials/nav";

export default function Home() {
  const { t } = useTranslation();
 
  const tagManagerArgs = {
    dataLayer: {
      event: "page_view",
      page_location: document.location.href,
      page_title: t("home-meta-title"),
    },
    };

  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    document.title = t("home-meta-title");
    AOS.init({
      duration : 2000
    });
  }, []);
  return (
    <>
    <Nav/>
    <main className="404-page">
          <section data-aos="zoom-y-out" className="hero full-bleed" >
             404
          </section>
    </main>
    <Footer/>
    </>
  );
}
