import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../images/wave-white.svg";
import hero_bk from "../images/kids-with-toys.jpg";
import maCcmv from "../images/ma-ccmv.png";
import natCmv from "../images/nat-cmv.png";
import cdc from "../images/cdc.png";
import cmvBug from "../images/cmv-bug.svg";
import moderna from "../images/moderna-logo.svg";
import AOS from "aos";
import { routes } from "../routes";

import CtaModule from "../partials/cta-module";
import {
	useSearchParams
} from "react-router-dom";
import Footer from "../partials/footer";
import Nav from "../partials/nav";

export default function WhatIsCMV() {
	const [search] = useSearchParams();
	const { t } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};

	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
		AOS.init({
			duration : 2000
		  });
	}, []);
	return (
		<>
					<Nav />
			<main className="what-is-cmv-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{backgroundPositionY: "40%",backgroundImage: "url(" + hero_bk + ")"}}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>What is CMV?</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					&nbsp;
					<h2 className="first-h2">What is CMV? </h2>
<ul>
<li>CMV is a common virus in the same virus family as chicken pox and cold sores. Infection can occur at any age but often in young children, usually causes mild or no symptoms, and has minimal health effects. Viruses in this family stay with us for life. </li>
<li>In contrast, children who are infected before birth – <strong>called “congenital” CMV or cCMV</strong> – can have severe and permanent disabilities.</li>
<li>CMV is the most common congenital infection and disproportionately affects racial/ethnic minorities. </li>
</ul> 

<h2>How does CMV spread?</h2>
 <ul>
	<li>Many common infections including CMV spread between young children in large group education and care centers. Infections spread by activities such as sharing toys, touching, or eating together.</li>
	<li>Children who get CMV - even if they do not appear sick - usually have large amounts of virus in their saliva or urine for months or even years.</li>
	<li>The virus can then spread to people who have contact with these body fluids, such as their caretakers.</li>
 </ul>
 <h2>Who is at risk for congenital CMV infection?</h2>
<ul>
	<li>CMV can spread from pregnant individuals to their developing baby before birth, which causes congenital CMV infection.</li>
	<li>A major risk factor for having a baby with cCMV is contact with saliva or urine of young children who attend large education and care centers.</li>
</ul>

<h2>What are the health effects of cCMV infection? </h2>
<ul>
	<li>Symptoms of cCMV can be found at birth, such as small head size, liver disease, or hearing loss.</li>
	<li>Symptoms can also develop or worsen later in childhood, such as cerebral palsy, seizures, or developmental delays.</li>
	<li>Hearing loss is the most common long-term health effect of cCMV.</li>
</ul>
<h2>Can babies be tested for cCMV?  
</h2>
<ul>
	<li>Before birth, cCMV can be diagnosed by testing amniotic fluid after 20-21 weeks of pregnancy.</li>
	<li>After birth, cCMV infection can be diagnosed by testing saliva or urine within 3 weeks of age.</li>
</ul>

<h2>What is the treatment for cCMV?</h2>
<ul>
	<li>Medicine for babies with moderate to severe symptoms at birth can decrease the risk of hearing loss or some developmental delays. The medicine should start within 1 month of age.</li>
	<li>Treatment for babies with mild to no symptoms is not currently recommended.</li>
	<li>Research is being done to improve treatment options for cCMV infection.</li>
	<li>There are no approved medicines or vaccines to prevent cCMV infection, but pregnant people can reduce their risk by avoiding contact with saliva or urine of young children.</li>
</ul>
				</section>
				<section className="highlighted">
					<div className="container">
					<h2 className="center no-subtext">Additional Resources</h2>
					<div className="resources">
						<div className="card-wrap">
							<a className="card" target="_blank" href="https://www.cdc.gov/cmv/">
								<img src={cdc}></img>
								<span>cdc.gov/cmv</span>
							</a>
						</div>
						<div className="card-wrap">
							<a className="card" target="_blank" href="https://www.nationalcmv.org/">
								<img src={natCmv}></img>
								<span>nationalcmv.org</span>
							</a>
						</div>
						<div className="card-wrap">
							<a className="card" target="_blank" href="https://nowiknowcmv.com/">
								<img src={moderna}></img>
								<span>nowiknowcmv.com</span>
							</a>
						</div>
						<div className="card-wrap">
							<a className="card" target="_blank" href="https://cmvmass.org/">
								<img src={maCcmv}></img>
								<span>cmvmass.org</span>
							</a>
						</div>
					</div>
						
					</div>
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}
