import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import blog09 from "../../images/doctors-talking.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";

export default function CollaborationIsKey() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundImage: "url(" + blog09 + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Collaboration is Key</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Collaboration is Key
					</h2>
				
					<p>August 2024</p>

					<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
					</p>

					&nbsp;

					<p>
						In January 2021, I began working with a core team at UMass Chan Medical School and Moderna to develop the <a target="_blank" href="https://cmvtransmitstudy.org/" >CMV TransmIT Study</a>. The study aims to discover basic information about CMV in childcare centers, such as how many people have the virus in their saliva, whether the virus is in an infectious state, and how it spreads. One of the major reasons for doing the study is to understand the natural process of CMV infection among large groups of children. This work may reveal how to reduce viral spread from these children to their pregnant caretakers, which in turn may reduce the risk of congenital CMV (cCMV). 
					</p>
					<p>
						Early in the development of the CMV TransmIT Study, a high priority was to assemble a research team with diverse areas of expertise and willingness to collaborate with colleagues. The Community Advisory Board, digital platform developers, data team, and many others routinely share their ideas and make group decisions in a productive and respectful environment. With collaboration as a guiding principle, our study team has grown to 15 members dedicated to child health and community engagement, four of whom routinely interact with center families and staff. To date, 11 early education and care (EEC) centers (with three more coming) have joined the research network. We are giving presentations at scientific conferences to report our findings. Completing this first cycle of the research process would not have happened without teamwork.
					</p>
					<p>
						More broadly, collaboration has been a critical part of efforts to reduce cCMV. Since 2013 when Utah became the first state to routinely test some newborns for CMV, awareness has grown not only among the public but also funders, companies, and regulatory agencies like the Food & Drug Administration (FDA). In 2018, the FDA <a target="_blank" href="https://www.fda.gov/news-events/press-announcements/fda-authorizes-first-test-aid-detecting-type-herpes-virus-newborns-called-cytomegalovirus">authorized the first test</a> to aid in detecting CMV in babies. Working with parents of children with cCMV, the FDA recently hosted a <a target="_blank" href="https://www.fda.gov/media/177869/download">listening session</a> to hear directly about parents’ decisions to start or decline antiviral treatment for their child, reasons for completing or stopping the full six-month course, and perspectives about their child participating in a cCMV clinical trial.  
					</p>
					<p>
						Multiple companies are developing a CMV vaccine, including <a href="https://clinicaltrials.gov/study/NCT05089630" target="_blank">Pfizer, GlaxoSmithKline (GSK)</a>, and <a href="https://trials.modernatx.com/study/?id=mRNA-1647-P301" target="_blank">Moderna</a>. The National CMV Foundation <a href="https://www.nationalcmv.org/overview/vaccine-development#:~:text=Currently%2C%20there%20is%20no%20CMV,next%205%20to%2010%20years." target="_blank">reports</a> that approximately ten phase I and two phase II clinical trials to develop cCMV interventions are ongoing or have been completed. For example, <a href="https://clinicaltrials.gov/study/NCT06145178?cond=Cytomegalovirus&intr=Vaccine&limit=100&sort=StudyFirstPostDate&aggFilters=funderType:industry,studyType:int&rank=1#study-overview" target="_blank">SpyBiotech Limited</a> is currently recruiting for a study in the United Kingdom to evaluate the safety and immune response of two different doses of a CMV vaccine in healthy adults 18 to 50 years old.  
					</p>
					<p>
					According to an African proverb, “It takes a village to raise a child.” Applying this notion to cCMV research, “It takes a village to protect a child” from a potentially devastating infection. Many whose lives or work have been touched—parents of children with cCMV, companies working on drugs or vaccines, the FDA, healthcare providers and educators, the CMV TransmIT Study and other researchers—are doing their part by working collaboratively toward a world where no baby is born with cCMV.
					</p>

					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}