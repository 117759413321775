import React from "react";
import { useTranslation } from "react-i18next";
import MyDataHelps from "@careevolution/mydatahelps-js";
import getBrandConfiguration from "../helpers/getBrandConfiguration"


export default function ctaModule(locale) {

  var brandConfiguration = getBrandConfiguration();
  function join() {
    console.log("MyDataHelps.startEmbeddedSurvey")
    MyDataHelps.startEmbeddedSurvey(brandConfiguration.projectCode, "Consent").then(function (result) {
      if (result.reason === "Completed") {
          //redirect to confirmation page with the link identifier in the url
          const accessKeyObj = result.taskResult.StepResults.find(obj => obj.Identifier === "AccessKey");
          const accessKey = accessKeyObj && accessKeyObj.QuestionResults[0].Result;

          window.location = window.location.origin + "/confirmation/" + (locale ?? "en") + "?participant=" + accessKey;
      }
    });
  }

  return (
    <section className="dark-bg cta-module" data-aos="zoom-y-out" >
    <div className="container">
      <h2 className="center no-subtext">How can I help?</h2>
      <h3 className="center no-subtext"> Join the study by following the steps below:</h3>
      <div className="col-3-md steps">
          <div className="card-wrap">
            <div className="card">
              <h3 className="card-header">
                Step 1
              </h3>
              <p>Take the online eligibility assessment.
              </p>
              {/* <div className="icon-wrap"><i className="fa-solid fa-circle-check">
                  <span className="sr-only">circle-check</span>
                </i></div> */}
              <div className="button-wrap">
              <button className="button" onClick={() => join()}>Determine eligibility</button>
              </div>
            </div>
          </div>
          <div className="card-wrap">
            <div className="card">
              <h3 className="card-header">
                Step 2
              </h3>
              <p>Complete the online consent and surveys.
              </p>
              <div className="icon-wrap"><i className="fas fa-signature">
                  <span className="sr-only">signature</span>
                </i></div>
            </div>
          </div>
          <div className="card-wrap">
            <div className="card">
            <h3 className="card-header">
              Step 3
              </h3>  
              <p>Allow your child to provide a saliva sample.
              </p>
              <div className="icon-wrap"><i className="fas fa-vial">
                  <span className="sr-only">test tube</span>
                </i></div>
                
            </div>
          </div>
      </div>
    </div>
  </section>
  )
}
