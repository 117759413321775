import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../images/wave-white.svg";
import hero_bk from "../images/kids01.jpg";
import why_we from "../images/kids-with-chairs.jpg";
import why_you from "../images/kids02.jpg";
import what_you from "../images/kid-with-doctor.jpg";
import how_you from "../images/kids-with-toys.jpg";
import blocks from "../images/kids-with-blocks.jpg"
import families from "../images/families.png"
import legos from "../images/kid-with-legos.jpg"
import table from "../images/kids-at-table.jpg"
import draw from "../images/kids-drawing.jpg"
import avatar from "../images/user-white.svg";
import info from "../images/Study_Overview_Centers.png";
import Modal from "../partials/modal";
import AOS from "aos";
import { routes } from "../routes";

import {
	useSearchParams
} from "react-router-dom";
import Footer from "../partials/footer";
import Nav from "../partials/nav";
import CtaModule from "../partials/cta-module";

export default function InfoForCenters() {
	const [search] = useSearchParams();
	const { t } = useTranslation();
	const gibson_bio = useRef(null)
	const delolio_bio = useRef(null)
	const kowalik_bio = useRef(null)

	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
		AOS.init({
			duration: 2000
		});
	}, []);
	return (
		<>
			<Nav />
			<main className="about-the-study-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundPositionY: "bottom", backgroundImage: "url(" + hero_bk + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container" style={{"margin-bottom":-65}}>
						<h1>Early Education and Care Centers Research Network</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="" data-aos="zoom-y-out">
					<div className="container">
					<div className="section-text">
							<h2>What is the Early Education and Care (EEC) Center Research Network?
							</h2>
							<p>The EEC Research Network is a consortium of centers that have joined UMass Chan Medical School as partner sites in the CMV TransmIT Study. Centers are based in the Worcester and Cambridge areas of Massachusetts. </p>
								<center><a className="button" style={{"margin-top":15, "width":700}} href="mailto: cmvtransmitstudy@umassmed.edu">Learn more about participating in the EEC Research Network  <span className="jump-arrow">
									<i className="fas fa-arrow-right">
									<span className="sr-only">go</span>
									</i>
									</span>
								</a></center>
					</div>
					</div>
				</section>
				<section className="highlighted" data-aos="zoom-y-out">
					<div className="container">
						<div className="section-text">
						<div className="left">
					<h2>Why join the EEC Research Network</h2>
							<p>By joining the network, centers and their familes can help advance CMV research toward:</p>
								<ul>
									<li>reducing the number of babies born with congenital CMV, and</li>
									<li>promoting a healthy pediatric community.</li>
								</ul>
							<p>There are additional benefits for centers who join the EEC Research Network. Reach out to the CMV TransmIT Study to learn more!</p>
					</div>
						</div>
					</div>
				</section>

				<section className="" data-aos="zoom-y-out">
					<div className="container col-2-md">
						<div className="section-text">
						<h2>What is the role of my center in the study?</h2>
							<p>As part of the EEC Research Network, your center will allow study staff to interact with children and their families to provide CMV education, enroll participants, and collect samples. We want to take research out of the lab and bring it to where people live and work!</p>
						</div>
						<div className="section-image">
							<img src={blocks} alt=""></img>
						</div>
					</div>
				</section>
				<section className="" data-aos="zoom-y-out">
					<div className="container">
						<div className="section-text">
							
							<div className="card" style={{"margin-top":-80, "margin-bottom": 8}} >
								<p><center>The CMV TransmIT Study Team is <strong>committed to building partnerships with centers</strong> to support mutually beneficial and optimal workflows.</center></p>
							</div>
							</div>
					</div>
				</section>
				<section className="highlighted" data-aos="zoom-y-out">
				<div className="container">
					<div className="section-text">
							<h2>Family participation in the CMV TransmIT Study is easy!</h2>
							<div className="section-text">
								<img src={info} alt=""></img>
							</div>
							
					</div>
				</div>
				</section>
				

				<section className="" data-aos="zoom-y-out">
					<div className="container">
						<div className="section-text">
							<h2>EEC Research Network Quotes
							</h2>
							<div className="card" style={{"margin-top":8, "margin-bottom": 8}} >
								<p><i>“The YWCA of Central Massachusetts believes our work is not just with young children but with the whole family. Collaborating with UMass Chan will give us the opportunity to provide connections to community resources and support to help families thrive and potentially reduce the risk of CMV transmission.”</i></p>
								<p><b>Darlene Belliveau, Director of Children's Services, YWCA, Worcester, MA</b></p>
							</div>

							<div className="card" style={{"margin-top":8, "margin-bottom": 8}} >
								<p><i>“With the dedicated leadership of Dr. Gibson, the CMV TransmIT Study is unique as it focuses on the transmission of CMV in the childcare setting, which is a novel approach. As a pediatrician, this study is important to help us better understand the spread of CMV in a large setting while also providing an opportunity to bring awareness of this common, yet rarely known, virus.”</i></p>
								<p><b>Lawrence Rhein, M.D., Chair, Department of Pediatrics, UMass Memorial Health</b></p>
							</div>
						</div>
					</div>
				</section>
			</main>

			<Footer />
		</>
	);
}
