import React from "react";
import modernalogo from "../images/moderna-logo.svg";
import umasslogo from "../images/umass-chan-logo.png";
import instagram from "../images/instagram.svg";
import twitter from "../images/twitter.svg";
import { useParams } from "react-router-dom";
import LanguageSwitcher from "./language-switcher";
import { useTranslation } from "react-i18next";

export default function Footer() {
  let { brand } = useParams();
  const { t,i18n } = useTranslation();
  return (
    <footer className="highlighted">
      <div className="container center">
        <div><h2>A research collaboration between</h2></div>
        <div className="collaborators">
           <img src={umasslogo} alt="U Mass Chan Medical School logo"></img>
           <span style={{padding:'1em'}}>&</span>
          <img src={modernalogo} alt="moderna logo"></img>
        </div>
        &nbsp;
        <div> 
          <h3>Contact the CMV TransmIT Study Team at <a href="tel:508-523-1181">508-523-1181</a></h3>
        </div>
        &nbsp;
        <div> 
          <h4>Additional Resources</h4>
            <a target="_blank" href="https://www.nationalcmv.org/">nationalcmv.org</a><br></br>
            <a target="_blank" href="https://cmvmass.org/">cmvmass.org</a>
        </div>
        
        <div className="socialmedia">
          <a href="https://twitter.com/CMVTransmIT" target="_blank">
            <img src={twitter} alt="twitter"></img>
          </a>
          <a href="https://www.instagram.com/cmvtransmitstudy/" target="_blank">
          <img src={instagram} alt="instagram"></img>
          </a>
        </div>
        &nbsp;
        <div>
        <div>For accessibility concerns, please <a href="mailto:info@careevolution.com">notify us</a>.</div>
        <small>© {(new Date().getFullYear())} CareEvolution, LLC.</small>    

      {/* <LanguageSwitcher /> */}
        </div>
      </div>
    </footer>
  );
}
