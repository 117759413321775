import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import blog08 from "../../images/conference-speaker.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";

export default function AdvocacyAndAwareness() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundImage: "url(" + blog08 + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Advocacy and Awareness</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Advocacy and Awareness: 2023 CMV Public Health and Policy Conference
					</h2>
				
					<p>November 2023</p>

					<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
					</p>

					&nbsp;

					<p>
						In October, I traveled to the 2023 CMV Public Health and Policy Conference in Salt Lake City, Utah where the inaugural conference—planned mainly by cCMV parents—was held 10 years earlier after the first newborn CMV screening law was passed in Utah. Glad to be meeting in person again, I connected with all types of colleagues including clinicians, researchers, parents, policymakers, industry partners, and many others. We debated optimal approaches to CMV screening, raising awareness, vaccine development, public policy, and legislation, all while delighting in progress and momentum since the original conference. I participated by giving a presentation on community-based research and the importance of developing relationships early in the process.
					</p>
					<p>
						Sharing updates and experiences about newborn CMV screening programs or legislation was a hot topic. Several workshops focused on <a href="https://www.health.state.mn.us/diseases/cytomegalovirus/index.html">Minnesota</a> as the first state in the nation to implement universal screening as of February 2023, which had followed passage of the Vivian Act in 2021 mandating review--then approval--of the recommended screening plan in early 2022. A similar bill mandating prenatal CMV education and universal newborn screening was introduced in the Massachusetts legislature in February 2021 and February 2023. While we don't yet know the outcome of the Joint Committee on Public Health public comment hearing which took place on November 15, 2023, we hope Massachusetts will join the increasing number of states providing this vital care to their youngest citizens.
					</p>
					<p>
						Updates on advocacy efforts focused on Canada and the steps taken to achieve universal newborn screening of CMV in several provinces, including raising awareness, supporting families, championing research, and advocating for change. We heard from Rob Tétrault, a dad from Winnipeg, Manitoba, whose son, Alexandre, was born with cCMV in 2008. Rob co-founded the Canadian CMV Foundation with his wife, Michelle, and he ran two half-marathons over 12 days in the summer 2023 to raise awareness of cCMV and universal newborn screening.
					</p>
					<p>
						We also heard feedback from pediatric primary care and infectious disease physicians, and parents, about CMV testing. Some questioned the purpose of newborn screening, especially since anti-viral treatment is not necessary for most infants based on current research data. Others expressed concerns about healthcare costs, theoretical harm to asymptomatic infants, limited capacity for triage or referrals in outpatient settings, and inadequate clinical guidelines. Parents expressed frustration about limited access to specialty services or testing. 
					</p>
					<p>
						These and many other logistical challenges are facing the national trend toward universal newborn CMV screening. Nevertheless, the conference reminded me about the energy, collegiality, and mutual support among people whose lives are touched by cCMV, and that our work is continuing to ensure that more children's lives are not affected by cCMV.
					</p>

					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}