import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../images/wave-white.svg";
import hero_bk from "../images/kids01.jpg";
import why_we from "../images/kids-with-chairs.jpg";
import why_you from "../images/kids02.jpg";
import what_you from "../images/kid-with-doctor.jpg";
import how_you from "../images/kids-with-toys.jpg";
import table from "../images/kids-at-table.jpg"
import Modal from "../partials/modal";
import AOS from "aos";
import { routes } from "../routes";

import {
	useSearchParams
} from "react-router-dom";
import Footer from "../partials/footer";
import Nav from "../partials/nav";
import CtaModule from "../partials/cta-module";

export default function AboutTheStudy() {
	const [search] = useSearchParams();
	const { t } = useTranslation();
	const gibson_bio = useRef(null)
	const delolio_bio = useRef(null)
	const kowalik_bio = useRef(null)
	const blake_bio = useRef(null)
	const hamel_bio = useRef(null)
	const platia_bio = useRef(null)
	const tran_bio = useRef(null)
	const davenport_bio = useRef(null)
	const lammi_bio = useRef(null)
	const callaghan_bio = useRef(null)
	const acar_bio = useRef(null)
	const belliveau_bio = useRef(null)
	const brown_bio = useRef(null)
	const terry_bio = useRef(null)
	const howe_bio = useRef(null)
	const colleran_bio = useRef(null)
	const luiselli_bio = useRef(null)
	const macharia_bio = useRef(null)

	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
		AOS.init({
			duration: 2000
		});
	}, []);
	return (
		<>
			<Nav />
			<main className="about-the-study-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundPositionY: "bottom", backgroundImage: "url(" + table + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>About the CMV TransmIT Study</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="first-content-section" data-aos="zoom-y-out">
					<div className="container col-2-md">
						<div className="section-text">
							<h2>Why are we doing this study?
							</h2>
							<p>We want to study the spread of cytomegalovirus (CMV) within early education and care centers. We hope to learn about how to decrease the spread of CMV within your communities.
							</p>
						</div>
						<div className="section-image">
							<img src={why_we} alt=""></img>
						</div>
					</div>
				</section>

				<section className="highlighted" data-aos="zoom-y-out">
					<div className="container col-2-md">
						<div className="section-image">
							<img src={why_you} alt=""></img>
						</div>
						<div className="section-text">
							<h2>Why should you participate?</h2>
							<p>By participating, you will be able to learn more about the risk of CMV in your family's life and help protect your community. Findings from this research will be used to understand CMV better. 
							</p>
						</div>

					</div>
				</section>

				<section className="" data-aos="zoom-y-out">
					<div className="container col-2-md">
						<div className="section-text">
							<h2>What does this study ask you to do?
							</h2>
							<p>Participating in this study involves:
							</p>
							<ul>
								<li>Taking an online eligibility assessment.</li>
								<li>Completing an online survey about your child and their household.</li>
								<li>Allowing your child to provide a saliva sample.</li>
							</ul>
						</div>
						<div className="section-image">
							<img src={what_you} alt=""></img>
						</div>
					</div>
				</section>

				<section className="highlighted" data-aos="zoom-y-out">
					<div className="container col-2-md">
						<div className="section-image">
							<img src={how_you} alt=""></img>
						</div>
						<div className="section-text">
							<h2>How can you join?
							</h2>
							&nbsp;
							<p><strong>Step 1.</strong> Taking the online eligibility assessment.
							</p>
							<p><strong>Step 2.</strong> Completing the online consent and surveys.
							</p>
						</div>

					</div>
				</section>

				

				



				<CtaModule />
			</main>
			<Footer />
		</>
	);
}
