import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import lego from "../../images/kid-with-legos.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";
export default function CmvAwareness() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundImage: "url(" + lego + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>June is CMV Awareness Month: Spreading the word about not spreading the virus</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Spreading the word about not spreading the virus
					</h2>
					
					<p>June 2023</p>

					<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
					</p>

					&nbsp;

					<p>Earlier this year, our communications consultant recommended I draft an article for <a href="https://theconversation.com/us" target="_blank"><i>The Conversation</i></a> for CMV Awareness Month. The Conversation is “a non-profit, independent news organization dedicated to unlocking the knowledge of experts for the public good.” 
					</p>
					<p>I wasn’t familiar with this platform, but she had kindled my interest and of course I will take any opportunity to educate about <a href="https://www.cdc.gov/cmv/index.html" target="_blank">congenital CMV infection</a>, so we moved ahead. A pitch to run an article was sent, and we waited to hear if they would accept a draft of my comments on the risk of congenital (infected before birth) CMV, newborn screening for CMV, and why it’s important to spread CMV awareness. 
					</p>
					<p>Needless to say, I was thrilled (and a bit apprehensive) when the email arrived inviting me to submit an article. Over the next few months, I worked with an editor at <i>The Conversation</i> to draft and finalize my article. On May 31, perfect timing on the day before international CMV Awareness Month, the article was published. 
					</p>
					<p>Entitled <a href="https://theconversation.com/cytomegalovirus-lies-dormant-in-most-us-adults-and-is-the-leading-infectious-cause-of-birth-defects-but-few-have-heard-of-it-202932" target="_blank"><i>Cytomegalovirus lies dormant in most US adults and is the leading infections cause of birth defects, but few have heard of it</i></a>, the article was “trending” on social media within the CMV community in just a few hours.  Shortly thereafter, <a href="https://twitter.com/CBSNews/status/1664104481187921922" target="_blank"><i>CBS News</i></a> also shared the article, which resulted in more than 20,000 views.  
					</p>
					<p>I am hoping the article will continue to reach families considering pregnancy or who are already pregnant offering them useful CMV information.  My greater hope is that parents of babies with congenital CMV no longer ask in disbelief, “Why didn’t anyone tell me?”
					</p>
					<p>For more information about CMV, visit <a href="https://www.nationalcmv.org">www.nationalcmv.org</a> or <a href="https://www.nowiknowcmv.com">www.nowiknowcmv.com</a>.
					</p>
					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}