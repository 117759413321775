import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import blog10 from "../../images/kissing-forehead.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";

export default function PreventingCmv () {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundPosition: "center calc(100% + 350px)", backgroundImage: "url(" + blog10+ ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Advocacy and Awareness</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Advocacy and Awareness: Preventing CMV Infection
					</h2>
				
					<p>February 2024</p>

					<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
					</p>

					&nbsp;

					<p>
						As we recognize International Prenatal Infection Prevention Month every February, I am reminded of the most effective prevention strategies for stopping the spread of CMV and the importance of newborn screening to detect CMV at birth.  
					</p>
					<p>
						Prevention tips recommended by the <a href="https://www.nationalcmv.org/overview/prevention-tips" target="_blank">National CMV Foundation</a> include: 
						<ul>
							<li><b>Keep your food, dinnerware, straws, and toothbrushes to yourself.</b> CMV infection could transfer to an unborn baby from saliva. </li>
							<li><b>Stop putting pacifiers in your mouth!</b> We have all done it – your baby’s pacifier drops on the floor, so you put it in your own mouth to “clean it,” but in fact, the saliva could transfer CMV to a pregnant person and their baby.  </li>
							<li><b>Save kisses for the forehead.</b> When you kiss a child under the age of six on the lips or cheek you could contact their saliva and may transfer CMV to a baby.  </li>
							<li><b>Wash those hands.</b> With all the viruses that could infect us, including CMV, it’s the right thing to do to stay safe.  </li>
						</ul>
					</p>
					<p>
						While prevention is an important tool, our country has experienced recent progress on advocacy to protect our smallest citizens and legislation mandating prenatal CMV education and/or newborn CMV screening. 
					</p>
					<p>
						Back in <a href="https://cmvtransmitstudy.org/advocacy-and-awareness/en" target="_blank">November 2023</a>, I wrote about how the Massachusetts Joint Commission on Public Health held a public hearing about proposed legislation mandating prenatal CMV education and universal newborn CMV screening here in the Commonwealth. I’m happy to report that in February 2024, the <a href="https://malegislature.gov/Bills/193/S1405" target="_blank">Senate bill</a> and the <a href="https://malegislature.gov/Bills/193/H2218" target="_blank">House bill</a> were favorably referred to the Committee on Health Care Financing. While we continue to hope this CMV legislation becomes law in Massachusetts, we are not the only state working toward protecting our babies from this potentially devastating prenatal infection.  
					</p>
					<p>
						States like <a href="https://portal.ct.gov/DPH/Family-Health/EHDI/CMV" target="_blank">Connecticut</a>, <a href="https://www.health.state.mn.us/diseases/cytomegalovirus/index.html" target="_blank">Minnesota</a>, <a href="https://familyhealth.utah.gov/cshcn/cmv/" target="_blank">Utah</a>, <a href="https://www.health.ny.gov/press/releases/2023/2023-09-29_newborn_screening.htm" target="_blank">New York</a>—and just a few weeks ago, <a href="https://app.leg.wa.gov/billsummary?billnumber=5829&year=2024" target="_blank">Washington State</a>—are leading the way in mandating newborn CMV screening. Federal legislation introduced by Senator Richard Blumenthal in 2023 would provide millions of dollars in research funding with the <a href="https://www.wtnh.com/news/health/blumenthal-introduces-federal-legislation-for-cmv-awareness-universal-screening/" target="_blank">Stop CMV Act of 2023</a>.  
					</p>
					<p>
					The work of advocacy groups, legislators, and people like you and me can reduce the number of babies born with and permanently disabled by CMV and other prenatal infections.  While CMV is very common and not a health concern for most people at any age, our job is to protect babies from being infected before they are born. 
					</p>

					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}