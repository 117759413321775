import React, { useEffect } from "react";
import {
	useSearchParams, Link, generatePath
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import AOS from "aos";
import wave from "../images/wave-white.svg";
import timeline from "../images/dash.svg";
import hero_bk from "../images/infants-playing.jpg";
import MyDataHelps from "@careevolution/mydatahelps-js"
import Footer from "../partials/footer";
import Nav from "../partials/nav";
import NewsModule from "../partials/news-module";
import BlogThumbnails from "../partials/blog-thumbnails";
import CtaModule from "../partials/cta-module";
import ScientificArticles from "../partials/scientific-articles";
import newsletter1 from '../documents/Newsletter-Issue-1-SEPT-2024-Volume-1.pdf';
import PressRelease1 from "../"
import { routes } from "../routes";

export default function Updates() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};

	const Accordion = ({ date, title, children }) => {
		const [isOpen, setOpen] = React.useState(false);
		return (
		<div className="accordion-wrapper">
			<div className="timeline" style={{backgroundImage: "url("+timeline+")"}}></div>
			<div
			className={`accordion-title ${isOpen ? "open" : ""}`}
			onClick={() => setOpen(!isOpen)}
			>
				<div className="date desktop-only">{date}</div>
				<i className="fas fa-angle-right rotate">
					<span className="sr-only">toggle</span>
				</i>
				<div className="readable-width">
				<div className="date mobile-only">{date}</div>
				{title}</div>
			</div>
			<div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
				<div className="accordion-content">{children}</div>
			</div>
		</div>
		);
	};

	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		AOS.init({
			duration : 2000
		  });
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="updates-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{backgroundImage: "url(" + hero_bk + ")", backgroundPosition: "center 60%"}}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Updates</h1>    
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="first-content-section" data-aos="zoom-y-out">
					<div className="container">		
					&nbsp;	
					<h2 className="center">Study Timeline</h2>	
						<Accordion date="September 2024" title="Inaugural Issue of Quarterly Newsletter">
							<embed src={newsletter1} type="application/pdf"   height="800px" width="800px" />
						</Accordion>
						<Accordion date="November 2023" title="Dr. Laura Gibson named 2023 Healthcare Innovator of the Year">
							<p>Our Principal Investigator Dr. Laura Gibson was honored by the Worcester Business Journal as the <a href="https://www.wbjournal.com/article/champions-of-health-care-gibson-is-a-global-leader-in-cmv-research-treatment-screening">2023 Healthcare Innovator of the Year</a>. The award was presented on December 14, 2023. Although she was unable to attend the award ceremony, her husband, Dr. Peter Gibson, spoke on her behalf. Join us once again in congratulating Dr. Gibson on her award and please enjoy Peter’s thoughts on his wife’s career.</p>
							<center><iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/GOIBMe8PJY0?si=MQu-V-sqkE9eQNKE&cc_load_policy=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></center>
							<div className="stats-refs">
							<div>Video credit: Worcester Business Journal/Brad Kane</div>
								</div>
						</Accordion>
						<Accordion date="August 2023" title="CMV TransmIT Stage 1 study launch">
							UMass Chan Medical School today announced it will begin enrolling participants at group child care and early childhood education settings in Worcester and Cambridge to examine the impact of cytomegalovirus (CMV) transmission. Read the <a href="https://www.prnewswire.com/news-releases/umass-chan-medical-school-announces-enrollment-in-study-to-examine-impact-of-cytomegalovirus-cmv-transmission-in-early-education-settings-301899731.html?tc=eml_cleartime" target="_blank">press release</a> to learn more about CMV TransmIT! 
						</Accordion>
						<Accordion date="April 2023" title="Institutional Review Board approves study application">
							After the initial application was submitted to the UMass Chan Institutional Review Board (IRB) in January 2023, the IRB approved the study proposal.  Any changes to the approved proposal over the course of the study are required to be submitted again to the IRB for review.
						</Accordion>
						<Accordion date="March 2023" title="YWCA Central Massachusetts becomes the first Early Education and Care (EEC) center to join the research network">
							After they were invited to join the research network of EEC centers, the YWCA in Worcester, MA accepted enthusiastically and became the first center to join. The next few months involved providing CMV education to center staff and families and planning for study enrollment to start.
						</Accordion>
						<Accordion date="January 2023" title="Application submitted to the UMass Chan Institutional Review Board ">
							No research involving humans can be done in the United States without review and approval by an Institutional Review Board or IRB. Most academic centers doing research have their own IRB, including UMass Chan. Submitting the initial application is a milestone for any study since it is the most detailed explanation of the research gathered into a package that will be reviewed by the IRB members. 
						</Accordion>
						<Accordion date="December 2022" title="Community Advisory Board meets for the first time">
							In December, members of the CMV TransmIT Study Community Advisory Board convened for the first meeting to advise the study team on the best way to develop and sustain relationships with Early Education and Care (EEC) centers. Board members had the opportunity to represent their perspectives and the interests of their communities and to provide input on study planning.
						</Accordion>		
						<Accordion date="July 2022" title="CareEvolution joins the project">
							CareEvolution is a company that specializes in developing digital platforms for research studies. The CMV TransmIT Study and CareEvolution teams began working together to design the platform and website for this study. Digital resources make it easier for study staff and participants to perform important study activities, such as sign the consent form and book a sample collection time. 
						</Accordion>
						<Accordion date="February 2022" title="First Project Plan approved by UMass Chan and Moderna ">
							After almost a year of planning, the final Project Plan outlined the parts and timeline for the study. The study is divided into two stages:
							<p>&nbsp;</p>
							<ul>
							<li><strong>Stage I</strong> includes building a network of Early Education and Care (EEC) centers in the Worcester and Cambridge MA areas, developing a Community Advisory Board to help guide the study team from a community perspective, offering enrollment in the study to families in each participating EEC center, collecting some information about children enrolled in the study (such as age, race/ethnicity, and household), and measuring the number of children who have CMV in their saliva.</li>
							<li><strong>Stage II</strong> includes continuing to build the research network of EEC centers, offer the study to families and their household members, and collect information about enrolled children and their household members, and doing some more detailed studies in the laboratory on how the immune system controls CMV.</li> 
							</ul>
						</Accordion>
						<Accordion date="March 2021" title={"Communications planning begins"}>
							Engagement with empHowered PR begins with communications planning to announce the study. A press release describing the research collaboration between UMass Chan Medical School and Moderna Therapeutics, Inc. to examine CMV in young children was distributed.  Collaboration with the UMass Chan Medical School and Moderna communications teams on social and other media, media and community relations, and thought leadership continues today.  
						</Accordion>
						<Accordion date="January 2021" title={t("update01")}>
							For many years, Dr. Gibson has served on the Scientific Advisory Board for the CMV vaccine being developed by Moderna. In January 2021, they began to talk about a study looking at CMV spread between infants and toddlers in large group childcare or school settings. This study would need a network of these centers interested in joining the project.  
						</Accordion>

					</div>
				</section>

<section className="highlighted">
    <div className="container">
    <h2 className="center no-subtext">Blog</h2>
	< BlogThumbnails />
	</div>
</section>
<section className="">
    <div className="container">
	<h2 className="center no-subtext">Scientific Articles</h2>
	<ScientificArticles />
	</div>
</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}


