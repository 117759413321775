import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, generatePath } from "react-router-dom";
import { routes } from "../routes";
import { useParams } from "react-router-dom";
import logo from "../images/cmv-logo.svg";
// import LanguageSwitcher from "./language-switcher";

export default function Nav() {
  let { brand } = useParams();
  const { t, i18n } = useTranslation();
  const [navbarOpen, setNavbarOpen] = useState(false);
  const isConfPage = window.location.href.indexOf("confirmation") > -1

  var menuToggle = document.getElementById( 'menu-toggle' );

  function toggleNav(){
    navbarOpen == false ? setNavbarOpen(true): setNavbarOpen(false)
        // Toggle class "opened". Set also aria-expanded to true or false.
        if ( -1 !== menuToggle.className.indexOf( 'opened' ) ) {
          menuToggle.className = menuToggle.className.replace( ' opened', '' );
          menuToggle.setAttribute( 'aria-expanded', 'false' );
        } else {
          menuToggle.className += ' opened';
          menuToggle.setAttribute( 'aria-expanded', 'true' );
        }
  }
var thisPath = window.location.pathname
if (thisPath == "/"){
  var activeLink = "home";
} else if (thisPath == "/en"){
  var activeLink = "home";
} else if (thisPath == "/es"){
  var activeLink = "home";
} else if (thisPath == "/home/en"){
  var activeLink = "home";
} else if (thisPath == "/home/es"){
  var activeLink = "home";
} else if (thisPath.includes("/what-is-cmv")){
  var activeLink = "what";
} else if (thisPath.includes("/about-the-study")){
  var activeLink = "about";
} else if (thisPath.includes("/information-for-centers")){
  var activeLink = "centers";
} else if (thisPath.includes("/meet-team")){
  var activeLink = "meet";
} else if (thisPath.includes("/contact-us")){
  var activeLink = "contact";
} else if (thisPath.includes("/updates")){
  var activeLink = "updates";
} else if (thisPath.includes("/confirmation")){
  var activeLink = ""
} else { 
  var activeLink = "updates" 
}

  return (
    <header>
            {/* <LanguageSwitcher /> */}
      <Link
            to="/"
            rel="home"
            className="header-logo"
          ><img
          src={logo}
          alt="logo"
        /> 
        <span className="tagline">{t("study-name-full")}</span>
      </Link>
      <nav className={`${navbarOpen ? " nav-open" : " nav-closed-on-mobile"}`} role="navigation" >
        <ul className="container">
          <li><Link
            className={`${(activeLink == "home") ? "active" : ""}`} 
            to={generatePath(routes.home, { locale: i18n.resolvedLanguage })}
            rel="home">Home</Link></li>
          <li><Link 
            className={`${(activeLink == "what") ? "active" : ""}`} 
            to={generatePath(routes.whatIsCmv, { locale: i18n.resolvedLanguage })}
            rel="what-is-cmv">What is CMV?</Link></li>
          <li><Link 
            className={`${(activeLink == "about") ? "active" : ""}`} 
            to={generatePath(routes.aboutTheStudy, { locale: i18n.resolvedLanguage })}
            rel="about-the-study">About the Study</Link></li>
          <li><Link 
            className={`${(activeLink == "centers") ? "active" : ""}`} 
            to={generatePath(routes.infoForCenters, { locale: i18n.resolvedLanguage })}
            rel="information-for-centers">Information for Centers</Link></li>
          <li><Link 
            className={`${(activeLink == "meet") ? "active" : ""}`} 
            to={generatePath(routes.meetTeam, { locale: i18n.resolvedLanguage })}
            rel="meet-team">Meet the Team</Link></li>
          <li><Link 
            className={`${(activeLink == "contact") ? "active" : ""}`} 
            to={generatePath(routes.contactUs, { locale: i18n.resolvedLanguage })}
            rel="contact-us">Contact Us</Link></li>
          <li><Link 
            className={`${(activeLink == "updates") ? "active" : ""}`} 
            to={generatePath(routes.updates, { locale: i18n.resolvedLanguage })}
            rel="updates">Updates</Link></li>
          


        </ul>
        <div className="nav-toggle">
            <button onClick={toggleNav} className="menu-toggle" id="menu-toggle" aria-expanded="false"><span className="screen-reader-text">Menu</span>
									<svg className="icon icon-menu-toggle" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100">
										<g className="svg-menu-toggle">
											<path className="line line-1" d="M5 13h90v14H5z"/>
											<path className="line line-2" d="M5 43h90v14H5z"/>
											<path className="line line-3" d="M5 73h90v14H5z"/>
										</g>
									</svg>
							</button>
        </div>

      </nav>
    </header>
  );
}
