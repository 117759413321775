import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import blog06 from "../../images/infants-reading.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";

export default function AwarenessAndIntervention() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundImage: "url(" + blog06 + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Time for a check-up!</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Newborn screening for CMV is imperative to raise awareness and provide early intervention
					</h2>

					<p>September 2023</p>

					<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
					</p>

					&nbsp;
					
					<p>For the past 60 years, the month of September has served as an opportunity to raise awareness about newborn screening and to help ensure all newborns are offered screening for life-threatening diseases.</p>
					<p>While congenital cytomegalovirus (cCMV) is the most common infection before birth affecting <a href="https://www.nationalcmv.org/overview/cmv-pregnancy">one in 200 babies in the U.S. each year</a>, Massachusetts does not include CMV on the list of conditions screened in the newborn period. As a result, <a href="https://www.mass.gov/doc/2020-birth-report/download">more than 66,000 babies</a> born in the Commonwealth in 2020 were not tested, but we are working hard to change that.</p>
					<p>Several states have led these efforts by passing legislation mandating universal cCMV screening of every newborn: <a href="https://content.govdelivery.com/bulletins/gd/MNMDH-308c2bf">Minnesota became the first state</a> in June 2021, followed in September 2023 by <a href="https://www.health.ny.gov/press/releases/2023/2023-09-29_newborn_screening.htm#:~:text=(September%252029%252C%25202023)%2520%25E2%2580%2593,all%2520babies%2520for%2520the%2520virus.">New York</a> as of October 2, 2023, and <a href="https://www.ctinsider.com/shoreline/article/ann-nyberg-advocates-cmv-testing-newborns-cheer-18142048.php">Connecticut</a> effective July 1, 2025. <a href="https://www.nationalcmv.org/about-us/advocacy">Other states</a>— including Utah, the first state to pass cCMV legislation in 2013—require CMV testing for babies who fail the newborn hearing screen. Most promising is <a href="https://www.wtnh.com/news/health/blumenthal-introduces-federal-legislation-for-cmv-awareness-universal-screening/">federal legislation</a> filed by Senator Richard Blumenthal (D-CT), which calls for raising CMV awareness, universal cCMV screening for all newborns in the U.S., and $30 million for research and education.</p>
					<p>Babies who show signs of cCMV at birth can be tested for the virus, evaluated for its potential effects on the brain, blood counts, liver, hearing, and vision over time, and considered for treatment or other interventions. Then why screen all babies for cCMV?</p>
					<p>The reason is that most (about 85 percent) infected babies have <i>no apparent signs of cCMV at birth</i>, so parents and healthcare providers don’t suspect or test for the virus within three weeks of age, as required for cCMV diagnosis. As a result, those children don’t have the opportunity for evaluation, proactive monitoring, such as hearing, appropriate early interventions, knowledge and support for their families, or the benefit of any future advances in care. In turn, those advances come from research that often depends on identifying children with cCMV.</p>	
					<p>In 2017, a multidisciplinary team at <a href="https://www.ummhealth.org/umass-memorial-medical-center/services-treatments/childrens-medical-centerpediatrics">UMass Memorial Health Children's Medical Center</a> developed and adopted a newborn CMV screening program modeled after the Utah protocol. Based on hearing loss as the most common effect of cCMV, babies born greater than 34 weeks of pregnancy who fail the hearing screen receive a saliva test for CMV before hospital discharge.  In contrast, all babies born less than 34 weeks of pregnancy receive a CMV test since they do not typically have a hearing screen in time for cCMV diagnosis.</p>
					<p>But <a href="https://pubmed.ncbi.nlm.nih.gov/36547382/">most birth centers in Massachusetts</a> do not have any formal cCMV screening program. To ensure that all pregnant individuals can receive CMV education and that all babies with cCMV can be identified and receive the care they and their families need, the <a href="https://cmvmass.org/news/">Massachusetts CMV Coalition</a> filed a bill in the <a href="https://malegislature.gov/Bills/192/H2338">House</a> and <a href="https://malegislature.gov/Bills/192/S1471">Senate</a> or the January 2021 legislative session mandating prenatal education and universal screening of CMV.  Pending the required third-party payor financial impact study, the bill was deferred to the current session that started January 2023.</p>
					<p>The Joint Committee on Public Health recently scheduled a <a href="https://malegislature.gov/Events/Hearings/Detail/4779">public hearing</a> for this and other bills on Wednesday, November 15, 2023, from 9:00 AM to 1:00 PM.  I encourage anyone who supports the cCMV bill to <a href="https://malegislature.gov/Search/FindMyLegislator">contact their legislators</a> and to submit their comments for the <a href="https://malegislature.gov/Events/Hearings/Detail/4779">public hearing</a> by the deadline Friday, November 10.  As a national leader in healthcare delivery, Massachusetts will hopefully join other states in providing vital cCMV education and care through universal newborn screening.</p>	

					<p>For more information about CMV, visit <a href="https://www.nationalcmv.org">www.nationalcmv.org</a> or <a href="https://www.nowiknowcmv.com">www.nowiknowcmv.com</a>.</p>
					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}