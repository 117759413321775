import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import lego from "../../images/kid-with-legos.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";
export default function TakingAChance() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundImage: "url(" + lego + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Taking A Chance</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Taking A Chance
					</h2>
					
					<p>March 2023</p>

					<p>
					<i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
					</p>
					<p><i>With Linda Kleeman, M.D., Maternal-Fetal Medicine Specialist</i></p>

					

					&nbsp;

					<p>On March 30, we recognize National Doctor’s Day to honor those who care for us every day.  Given the complexities and rapid advancements in healthcare, challenging clinical situations are often best managed collaboratively. In this case, no other option would have worked.
					</p>
					<p>I was contacted by the <a href="https://www.nationalcmv.org/" target="_blank">National CMV Foundation</a> on behalf of a woman in her first trimester of pregnancy. Sarah (not her real name) was interested in potential treatment for <a href="https://www.nationalcmv.org/overview/cmv-transmission" target="_blank">primary CMV infection</a>. In other words, she happened to be infected with the virus for the first time in her life while pregnant.
					</p>
					<p>CMV belongs to the same virus family as cold sores, chicken pox, and some others, all of which live with us for life. More than half of people in the U.S. are infected with CMV by the age of 40, but the vast majority of children and adults experience very mild or no symptoms with their initial (primary) CMV infection. Eventually, a normal immune system learns how to keep CMV under control, so we don't become sick and don't even know the virus is in our body over a lifetime. If it's seemingly inconsequential at any age, then how is CMV the most common congenital infection and the leading infectious cause of birth defects worldwide?
					</p>
					<p><i>How could that have been diagnosed?</i> I wondered.
					</p>
					<p>CMV testing is not standard for early prenatal care, mainly because there is no available treatment in pregnancy and any woman can have a baby with <a href="https://www.cdc.gov/cmv/index.html"target="_blank">congenital CMV infection</a> (cCMV) regardless of her test results.
					</p>
					<p>During our first phone call, Sarah explained that her husband had recently been diagnosed with primary CMV, and her testing showed the same result. <i>Those are some astute clinicians</i> I marveled. Primary CMV during the first trimester carries the highest risk of stillbirth or severe effects if the virus spreads to the fetus. Without treatments—or even the ability to test for fetal CMV infection so early—the main options are to continue or terminate the pregnancy despite very little information on which to base this decision.
					</p>
					<p>Like the <a href="https://pubmed.ncbi.nlm.nih.gov/22465669/" target="_blank">majority of women</a>, Sarah had never heard of CMV and was not aware she had <a href="https://pubmed.ncbi.nlm.nih.gov/3010113/" target="_blank">one of the major risk factors</a> for infection: a toddler in daycare. Infections spread easily between young children in large groups, and CMV is no exception.  But unlike many other common viruses, CMV stays in the body for life. Although healthy toddlers typically have mild or even no symptoms when they get CMV, they can have infectious virus in saliva and urine for weeks or even months. When an unsuspecting pregnant caretaker is exposed to those body fluids—and who isn’t when taking care of a toddler – then they can get CMV as well. 
					</p>
					<p>After being diagnosed with primary CMV, Sarah was referred to Dr. Linda Kleeman, a Maternal-Fetal Medicine specialist for complicated pregnancies. Determined to explore alternatives, she had already found <a href="https://pubmed.ncbi.nlm.nih.gov/32919517/" target="_blank">a study</a> about valacyclovir to prevent CMV transmission to the fetus. Valacyclovir is commonly used in pregnancy for prevention or treatment of <a href="https://www.cdc.gov/stiapp/herpes-genital_ado_adult.html" target="_blank">genital herpes</a>. However, sufficient research about its effectiveness for cCMV prevention is lacking, and the much higher dose (up to 16 times) poses several risks. Valacyclovir is used for this purpose in some areas of the world, but not the U.S. Despite these limitations, Sarah asked me to talk with her doctor and consider providing this treatment.  
					</p>
					<p>Thus began a challenging and rewarding collaboration between Dr. Kleeman and me, both of us caring for Sarah from our respective areas of expertise. As an infectious disease doctor, I had prescribed valacyclovir countless times, knew its risks, and was quite familiar with the protocol for cCMV prevention, but neither of us had used the drug for this purpose. We wondered <i>What would colleagues do in this case? Would the treatment need special approval?</i>  After settling these and other questions and performing additional tests to confirm her eligibility and assess her individual risks, we agreed to start treatment for Sarah. Her course was complicated: she was required to drink a huge amount of fluid daily, needed frequent monitoring with bloodwork and ultrasounds, and developed various side effects. But the three of us—specialists and patient—worked together cautiously and diligently to get her through the process. 
					</p>
					<p>We don’t know the outcome yet, and if Sarah’s baby is not CMV infected, we won’t ever know if the treatment helped. Either way, this case highlighted an essential part of congenital CMV prevention: patient and provider awareness leads to the best care. 
					</p>
					<p>For more information about CMV, visit <a href="https://www.nationalcmv.org">www.nationalcmv.org</a> or <a href="https://www.nowiknowcmv.com">www.nowiknowcmv.com</a>.
					</p>
					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}