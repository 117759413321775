import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import blog05 from "../../images/infant-crawling.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";

export default function TimeForACheckup() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundImage: "url(" + blog05 + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Time for a check-up!</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Time for a check-up!
					</h2>
				
					<p>April 2023</p>

					<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
					</p>

					&nbsp;

					<p>On April 24, 2023, after months of hard work, the <a href="https://cmvtransmitstudy.org/">CMV Transmission and Immune Tracking (TransmIT) Study</a> received the email we had been waiting for: The UMass Chan Medical School Institutional Review Board (IRB) had approved our proposal! That meant the study was considered ethical and safe, and we were cleared to start enrollment of children at Early Education and Care (EEC) centers.</p>
					<p>The study will examine how CMV spreads between toddlers at these centers and to their household members, and how their immune systems control the virus. Contact with young children who regularly attend large group programs is one of the major risk factors for a pregnant person to have a baby with congenital CMV (cCMV) infection. Findings from the study are intended to help reduce this risk and therefore the number of babies born with cCMV every year.</p>
					<p>Our goal of IRB approval had been more than a year in the making, with experts on the laboratory, data, study, administrative and communications teams working tirelessly to ensure the study could move forward. As the Principal Investigator, I watched an extraordinary group of people work collaboratively to achieve this success.</p>
					<p>But now, our work truly begins!</p>
					<p>By enrolling in the study, participants will learn more about the risk of CMV in their lives and help protect their communities by reducing CMV spread. Participation in the study involves having a parent or guardian of an eligible child complete an online survey about the child and their household and allow the child to provide a saliva sample. We will test the saliva for CMV to check approximately how many children in a center are positive. The virus is not a health concern for those children – CMV goes around a childcare center like many other common infections – but knowing how many children <i>could</i> be transmitting the virus in their saliva is an important first step in understanding the pathways of spread.</p>
					<p>One of the special parts of this study is building relationships with EEC centers. Located in Worcester, MA, the <a href="https://ywcacm.org/">YWCA of Central Massachusetts</a> joined as our first site. While most staff had never heard of CMV, they quickly recognized the significance and potential impact of this virus on children and their families. We are so grateful for their interest in getting involved, relentless enthusiasm about learning, and generosity with their time. As we create a research network of EEC centers that did not exist before, the YWCA will be a crucial partner in educating our team and shaping the study right from the beginning.</p>
					<p>To expand our awareness of the community perspective on research and our study, we also created a Community Advisory Board to guide us through the entire project. The Board consists of members who bring their expertise in childcare, occupational safety, hearing loss, community research, and most importantly, parenting of children with cCMV, and who attend childcare.</p>
					<p>Now that we are “live,” I will be here every month to share regular updates about the study and reflections on our new experiences, but mostly to offer reasons, strategies, and encouragement to raise awareness of CMV. In the meantime, check out our study website <a href="https://cmvtransmitstudy.org/">here</a> to learn more.</p>
					<p>For more information about CMV, visit <a href="https://www.nationalcmv.org">www.nationalcmv.org</a> or <a href="https://www.nowiknowcmv.com">www.nowiknowcmv.com</a>.</p>
					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}