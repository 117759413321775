import React from "react";
import { Link, generatePath } from "react-router-dom";
import { routes } from "../routes";
import { useTranslation } from "react-i18next";
import news01 from "../images/scienceplaceholder02.png";
import news02 from "../images/scienceplaceholder01.png";
import news03 from "../images/scienceplaceholder02.png";

export default function BackToUpdates() {
  const { t,i18n } = useTranslation();
  return (
    <Link 
    to={generatePath(routes.updates, { locale: i18n.resolvedLanguage })}
    className="button back-to-updates">
    <span className="">
        <i className="fas fa-arrow-left">
        <span className="sr-only">go</span>
        </i>
    </span>

    <span className="text-wrap">Back to Study Updates</span>
    </Link>
  )
}
