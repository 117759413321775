import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";
import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";
export default function NewbornScreening() {
	const [search] = useSearchParams();
	const { t, i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
			<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundImage: "url(" + hero_bk + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Newborn screening for cCMV remains limited in most states</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
					<h2>Newborn screening for cCMV remains limited in most states
					</h2>
					
					<p>February 2023</p>

					<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
					</p>

					&nbsp;

					<p>Every February, infectious disease physicians around the world recognize International Prenatal Infection Prevention Month to promote awareness of infections transmitted from mother to baby during pregnancy. The terms "prenatal" or "congenital" are often used to describe infections – or any other condition – acquired before birth. The World Health Organization estimates that approximately <a href="https://www.who.int/en/news-room/fact-sheets/detail/children-reducing-mortality">700,000 infants die each year due to infectious diseases</a> of any kind.
					</p>
					<p>What infection should a prenatal awareness or prevention campaign target to have the most impact? Certainly "the most common one" would be a natural answer. However, the most common congenital infection globally also remains the most unknown to expectant mothers: congenital cytomegalovirus (cCMV).
					</p>

					<p>CMV belongs to the same virus family as cold sores, chicken pox, and some others, all of which live with us for life. More than half of people in the U.S. are infected with CMV by the age of 40, but the vast majority of children and adults experience very mild or no symptoms with their initial (primary) CMV infection. Eventually, a normal immune system learns how to keep CMV under control, so we don't become sick and don't even know the virus is in our body over a lifetime. If it's seemingly inconsequential at any age, then how is CMV the most common congenital infection and the leading infectious cause of birth defects worldwide?
					</p>
					<p>The reason is that CMV – unlike the other viruses in its family – can pass from mother to fetus, including early in pregnancy when the immune system and organs, such as the brain, are still developing. While most newborns appear normal, approximately 1 of every 200 infants is born with cCMV (about 30,000 infants per year in the U.S.) and nearly 20 percent of them have long term neurodevelopmental effects from infection, such as hearing loss or cerebral palsy. Pregnant women and babies are tested for many other potentially serious conditions, so when do they get a CMV test?
					</p>
					<p>Pregnant women and babies are not routinely screened for CMV. One reason is that screening in pregnancy is not accurate for telling whether the fetus is or will be infected. While no approved treatment is available for pregnancies at risk, <a href="https://pubmed.ncbi.nlm.nih.gov/32919517/">some initial studies</a> outside the U.S. suggest that the drug valacyclovir may reduce the chances of cCMV infection. Most U.S. states and birth centers also do not routinely screen newborns for CMV, although a very accurate test is available, and many reports outline the benefits of early detection.  Since they usually appear normal at birth, these infants are not otherwise diagnosed and therefore not considered for treatment or monitored for effects of cCMV infection that may develop later in childhood.
					</p>
					<p>To assess the current state of cCMV screening in their state, members of the Massachusetts Congenital CMV Coalition (MCC) conducted a <a href="https://www.mdpi.com/2409-515X/8/4/65">study of birth hospitals</a> that was recently published in the International Journal of Neonatal Screening, of which I was a senior author. The study revealed that fewer than half of birth hospitals are consistently screening infants for cCMV infection, and of those, only a few have a written protocol that is vetted and practiced by relevant care providers. In fact, only two out of 33 hospitals that completed at least one question on the survey perform cCMV screening on all infants admitted to the newborn nursery. More broadly, the Commonwealth of Massachusetts currently screens all newborns for 66 different conditions, but cCMV is not among them.
					</p>
					<p>While the study recommends a cohesive strategy involving standardized education and screening guidelines, it also states that legislation at the state and federal levels should be considered to reduce the incidence and burden of cCMV disease on children and their families. Utah was the first state to pass CMV legislation of any type in July 2013,  Minnesota was the first – and remains the only – state to mandate universal CMV screening, and so far 17 states have enacted laws requiring cCMV education and/or limited screening. Unfortunately, we in Massachusetts are in the majority without such a requirement, but we hope not for long. In February 2021, the MCC participated in drafting and filing a bill that would require prenatal education and universal cCMV screening of all newborns in Massachusetts.
					</p>
					<p>To support this and many other pending state bills, we will continue to study and raise awareness of cCMV. The CMV Transmission and Immune Tracking (TransmIT) Study aims to understand how CMV spreads among children in large group care settings and to their household members, and how the immune system controls CMV in their body fluids and therefore the risk of spread to pregnant individuals. During this initial stage, we are inviting early education and care (EEC) centers in the Worcester, Mass. and Cambridge, Mass. areas to join our research network as partners on the study. This work is intended to inform the design of CMV prevention and treatment strategies, such as a drug, vaccine, or other types of medical care that reduce the risk of cCMV infection. Enrollment is due to begin in spring 2023. Watch for study updates and milestones as well as new information about CMV in the coming months!
					</p>
					<p>In the spirit of International Prenatal Infection Prevention Month, the CMV TransmIT Study targets "the most common one" by raising awareness of CMV and supporting the mission of reducing infections transmitted from mother to baby during pregnancy.
					</p>
					<p>For more information about CMV, visit <a href="https://www.nationalcmv.org">www.nationalcmv.org</a> or <a href="https://www.nowiknowcmv.com">www.nowiknowcmv.com</a>.
					</p>
					<BackToUpdates />
				</section>
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}