import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../../images/wave-white.svg";
import hero_bk from "../../images/kids01.jpg";
import maCcmv from "../../images/ma-ccmv.png";
import natCmv from "../../images/nat-cmv.png";
import cdc from "../../images/cdc.png";
import cmvBug from "../../images/cmv-bug.svg";
import moderna from "../../images/moderna-logo.svg";
import NewsModule from "../../partials/news-module";
import BackToUpdates from "../../partials/back-to-updates";
import { routes } from "../../routes";

import CtaModule from "../../partials/cta-module";
import {
	useSearchParams, Link
} from "react-router-dom";
import Footer from "../../partials/footer";
import Nav from "../../partials/nav";

export default function CmvInNewborns() {
	const [search] = useSearchParams();
	const { t,i18n } = useTranslation();
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};

	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
	}, []);
	return (
		<>
					<Nav />
			<main className="blog-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{backgroundImage: "url(" + hero_bk + ")"}}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Cytomegalovirus (CMV) in Newborns </h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section> 
				<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">

<h2>Cytomegalovirus (CMV) in Newborns </h2>

<p>January 2023</p>

<p><i>By Laura Gibson, M.D., Principal Investigator, CMV TransmIT Study | Adult and Pediatric Infectious Disease and Immunology specialist at UMass Chan Medical School</i>
</p>

&nbsp;

<p>“Why didn’t anyone tell me?” is a common response from parents when they find out their newborn is infected with cytomegalovirus or CMV. CMV is the leading infectious cause of birth defects and neurodevelopmental disabilities in the U.S., but few states screen infants for it. 
</p>
 
<h3>
What is cytomegalovirus? </h3>

<p>CMV is in the herpesvirus family along with the viruses that cause mono, cold sores, and chicken pox. It commonly passes by contact between people of all ages and within communities such as households, daycare centers, or schools, and usually causes mild or even no symptoms. More seriously, CMV can also pass from mother to fetus during pregnancy (which is called congenital CMV or cCMV) and cause significant health problems for infected newborns. 
</p>
 
<h3>
What are symptoms of congenital CMV (cCMV)? </h3>

<p>Studies show that one in every 200 babies is born with cCMV. Most infected babies don’t have symptoms, but one in five  experience long-term health problems, which may include: 
</p>
<ul>
	<li>Hearing loss (this may be found during a regular newborn hearing screening)</li>
	<li>Small size, including small head size </li>
	<li>Enlarged liver or spleen</li>
	<li>Yellow color of skin (jaundice)</li>
	<li>Small broken blood vessels under the skin</li>
	<li>Eye problems</li>
	<li>Seizures</li>
	<li>Mental and physical disabilities</li>
</ul>

<p>The symptoms of cCMV may look like other health conditions. Talk with your baby's health care provider if you think your baby may have CMV infection. 
</p>
 

<h3>How is CMV in newborns diagnosed? </h3>

<p>Most CMV infections in healthy adults and children are not diagnosed because the virus causes few symptoms. But tests for diagnosis of cCMV infection can be performed on: 
</p>
<ul>

</ul>
<li>amniotic fluid or blood of the fetus</li>
<li>urine and saliva of infants within three weeks of age</li>
<p>Other tests may include:</p>
<ul>
<li>Blood tests, such as complete blood count (CBC), liver function tests, and tests specific for the virus itself</li>
<li>Ultrasound, CT scan, or MRI of the brain</li>
<li>Hearing exams</li>
<li>Eye exams</li>
</ul>
<h3>How is CMV in newborns treated?</h3>
<p>Treatment will depend on the severity of your child’s symptoms. In general, treatment isn’t recommended for healthy newborns with no symptoms and is routinely given to the sickest babies. But current studies don’t clearly show a benefit for newborns with more limited symptoms, such as a rash, abnormal blood tests, or hearing loss without other abnormalities. Several studies are actively trying to determine the best approach for these infants. cCMV medicines can have significant side effects, so doctors want to be sure treatment will benefit infected infants more than they cause problems. 
</p>
 <h3>What can I do to prevent CMV in my child?</h3>
<p>Because it’s so common and people with infection usually aren’t sick, it can be hard to prevent CMV from passing between people through close contact. For example, young children with CMV infection can shed the virus in their saliva or urine for weeks or even months, making transmission of the virus particularly easy in daycare, early education, or household settings where playing, sharing toys, eating, and diaper changes are daily opportunities for infection.  
</p>
<p>These measures may help to prevent CMV infection:</p>
<ul>
	<li>Avoid contact with saliva by kissing a young child on the forehead instead of the lips. </li>
<li>Wash your hands often with soap and water for 15-20 seconds, especially after changing diapers, feeding children, wiping a child's nose or mouth, or touching toys. 
</li>
<li>Don’t share food, drinks or eating utensils with young children. 
</li>
<li>Don’t put a child’s pacifier in your mouth. 
</li>
<li>Clean toys, countertops and other surfaces that come in contact with children’s urine or saliva. 
</li>
</ul>








<BackToUpdates />
				</section>
				{/*  <section className="highlighted">
				<div className="container">
				<h2 className="center no-subtext">CMV in the News</h2>
				<NewsModule />
				</div>
				</section> */}
				<CtaModule />
			</main>
			<Footer />
		</>
	);
}
