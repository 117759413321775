import React from "react";
import { Link, generatePath } from "react-router-dom";
import { routes } from "../routes";
import { useTranslation } from "react-i18next";
import nejm from "../images/logo-nejm.png";
import pidj from "../images/logo-pediatric-infectious-disease-journal.png";
import jpeds from "../images/logo-jpeds.png";
import pediatrics from "../images/logo-pediatrics.png";
import bjog from "../images/logo-bjog.png";
import ajog from "../images/logo-ajog.png";
import jid from "../images/logo-jid.png";
import cid from "../images/logo-cid.png";
import lancet from "../images/logo-lancet.png";
import kimberlinetal2015 from '../documents/Kimberlin2015-VGCV-3-vs-6-mo.pdf';
import adleretal1989 from '../documents/Adler-CMV-infec-daycare-workers.pdf';
import adleretal1991 from '../documents/Adler-CMV-infec-mothers.pdf';
import adleretal1996 from '../documents/Adler-CMV-MTCT-prevent-behav-RCT.pdf';
import fowleretal2018 from '../documents/Fowler-cCMV-racial-ethnic-differences.pdf';
import fowleretal2017 from '../documents/Fowler-cCMV-targeted-screening-hearing.pdf';
import khaliletal2017 from '../documents/Khalil-cCMV-guidelines-OB-11.17.pdf';
import marshalletal2009 from '../documents/Marshall-CMV-exposure-women-with-child-in-daycare.pdf';
import mussietal2018 from '../documents/Mussi-Pinhata-cCMV-and-high-seroprevalence.pdf';
import mussietal2009 from '../documents/Mussi-Pinhata-cCMV-birth-prevalence-high-CMV-pos-pop.pdf';
import shaharetal2020 from '../documents/Shahar-Nissan-CMV-pg-VACV-RCT.pdf';
import news02 from "../images/scienceplaceholder01.png";
import news03 from "../images/scienceplaceholder02.png";

export default function ScientificArticles() {
    const { t,i18n } = useTranslation();
  return (
    <div className="news">
        <div className="card-wrap">
            <a href={adleretal1989} 
            target="_blank"
            className="card">
                <span className="img-wrap"><img src={nejm}></img></span>
                <span className="details">
                    <span className="title">Cytomegalovirus and Child Day Care: Evidence for an Increased Infection Rate Among Day-Care Workers</span>
                    <small className="authorship">
                        <span>S.P. Adler et al</span>
                        <span>November 1989</span>
                    </small>
                </span>
            </a>
        </div>
        <div className="card-wrap">
            <a href={adleretal1991} 
            target="_blank"
            className="card">
                <span className="img-wrap" style={{background:'#036e3c',padding:'10px'}}>
                <img src={pidj}></img></span>
                <span className="details">
                    <span className="title">Cytomegalovirus and child day care: risk factors for maternal infection</span>
                    <small className="authorship">
                        <span>S.P. Adler, MD</span>
                        <span>August 1991</span>
                    </small>
                </span>
            </a>
        </div>
        <div className="card-wrap">
            <a href={adleretal1996} 
            target="_blank"
            className="card">
                <span className="img-wrap" style={{background:'#036e3c',padding:'10px'}}>
                <img src={pidj}></img></span>
                <span className="details">
                    <span className="title">Prevention of child-to-mother transmission of cytomegalovirus by changing behaviors: a randomized controlled trial</span>
                    <small className="authorship">
                        <span>S.P. Adler et al</span>
                        <span>March 1996</span>
                    </small>
                </span>
            </a>
        </div>
        <div className="card-wrap">
            <a href={marshalletal2009} 
            target="_blank"
            className="card">
                <span className="img-wrap" style={{background:'#6a6c6e',padding:'15px'}}>
                <img src={ajog}></img></span>
                <span className="details">
                    <span className="title">Congenital Cytomegalovirus Infection: Update on Treatment</span>
                    <small className="authorship">
                        <span>B.C. Marshall &#38; S.P. Adler</span>
                        <span>February 2009</span>
                    </small>
                </span>
            </a>
        </div>
        <div className="card-wrap">
            <a href={mussietal2009} 
            target="_blank"
            className="card">
                <span className="img-wrap" style={{background:'#145694'}}>
                <img src={cid}></img></span>
                <span className="details">
                    <span className="title">Birth Prevalence and Natural History of Congenital Cytomegalovirus Infection in a Highly Seroimmune Population</span>
                    <small className="authorship">
                        <span>M.M. Mussi-Pinhata et al</span>
                        <span>August 2009</span>
                    </small>
                </span>
            </a>
        </div>
        <div className="card-wrap">
            <a href={kimberlinetal2015} 
            target="_blank"
            className="card">
                <span className="img-wrap">
                <img src={nejm}></img></span>
                <span className="details">
                    <span className="title">Valganciclovir for Symptomatic Congenital Cytomegalovirus Disease</span>
                    <small className="authorship">
                        <span>D.W. Kimberlin et al</span>
                        <span>March 2015</span>
                    </small>
                </span>
            </a>
        </div>
        <div className="card-wrap">
            <a href={fowleretal2017} 
            target="_blank"
            className="card">
                <span className="img-wrap">
                <img src={pediatrics}></img></span>
                <span className="details">
                    <span className="title">A Targeted Approach for Congenital Cytomegalovirus Screening Within Newborn Hearing Screening</span>
                    <small className="authorship">
                        <span>K.B. Fowler et al</span>
                        <span>February 2017</span>
                    </small>
                </span>
            </a>
        </div>
        <div className="card-wrap">
            <a href={khaliletal2017} 
            target="_blank"
            className="card">
                <span className="img-wrap" style={{background:'#13396e',padding:'10px'}}>
                <img src={bjog}></img></span>
                <span className="details">
                    <span className="title">Congenital Cytomegalovirus Infection: Update on Treatment</span>
                    <small className="authorship">
                        <span>A. Khalil et al</span>
                        <span>November 2017</span>
                    </small>
                </span>
            </a>
        </div>
        <div className="card-wrap">
            <a href={fowleretal2018} 
            target="_blank"
            className="card">
                <span className="img-wrap" style={{padding:'15px'}}>
                <img src={jpeds}></img></span>
                <span className="details">
                    <span className="title">Racial and Ethnic Differences in the Prevalence of Congenital Cytomegalovirus Infection</span>
                    <small className="authorship">
                        <span>K.B. Fowler et al</span>
                        <span>September 2018</span>
                    </small>
                </span>
            </a>
        </div>
        <div className="card-wrap">
            <a href={mussietal2018} 
            target="_blank"
            className="card">
                <span className="img-wrap" style={{background:'#af1d38'}}>
                <img src={jid}></img></span>
                <span className="details">
                    <span className="title">Seroconversion for Cytomegalovirus Infection During Pregnancy and Fetal Infection in a Highly Seropositive Population: “The BraCHS Study”</span>
                    <small className="authorship">
                        <span>M.M. Mussi-Pinhata et al</span>
                        <span>October 2018</span>
                    </small>
                </span>
            </a>
        </div>
        <div className="card-wrap">
            <a href={shaharetal2020} 
            target="_blank"
            className="card">
                <span className="img-wrap"> <img src={lancet}></img></span>
                <span className="details">
                    <span className="title">Seroconversion for Cytomegalovirus Infection During Pregnancy and Fetal Infection in a Highly Seropositive Population: “The BraCHS Study”</span>
                    <small className="authorship">
                        <span>K. Shahar-Nissan et al</span>
                        <span>September 2020</span>
                    </small>
                </span>
            </a>
        </div>
        


    </div>
  )
}
