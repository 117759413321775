export default function getBrandConfiguration() {
	//dev is the default
	var projectCode = "NVDQSM";
	//HACK
	if (process.env.REACT_APP_BUILD_ENV === "production") {
		projectCode = "LHLQWM";
	}
	if (process.env.REACT_APP_BUILD_ENV === "qa") {
		projectCode = "NVDQSM";
	}

	//TODO put other brand-specific parameters in here in the future
	return {
		projectCode: projectCode
	}
}